* ::-webkit-scrollbar {
  display: none;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}

ul {
  padding: unset;
  margin: unset;
  a {
    font-size: 11px;
    line-height: 24px;
    font-weight: 300;
    letter-spacing: 2px;
    font-family: helvetica;
    display: block;
    height: 24px;
    text-transform: uppercase;
    cursor: pointer;
    transition: color 0.5s ease;
    &:hover {
      color: change-color($color: #000000, $alpha: 0.25);
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
