.About {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  $block: &;

  &__container {
    background-image: url("./karissa-full-width.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    width: 100%;
    height: calc(100% - 150px);
    object-fit: cover;
    @media screen and (max-height: 500px) {
      width: 50%;
      height: calc(100% - 175px);
    }
  }
  span {
    font-size: 11px;
    line-height: 24px;
    font-family: helvetica;
    font-weight: 300;
    letter-spacing: 2px;
    padding: 15px;
    @media screen and (max-width: 1000px) {
      margin-top: 30px;
    }
    // @media screen and (max-height: 500px) {
    //   margin-top: unset;
    // }
  }

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
}
