.ImageDescription {
  $block: &;
  margin-top: 50px;
  margin-left: 25px;
  padding-bottom: 100px;
  &__styling,
  &__photography,
  &__talent,
  &__featuring,
  &__credits {
    &__text {
      display: inline-block;
      font-size: 11px;
      line-height: 24px;
      font-family: helvetica;
      font-weight: 300;
      letter-spacing: 2px;
      max-width: 70vw;
    }
    h3 {
      font-size: 11px;
      line-height: 30px;
      font-family: helvetica;
      letter-spacing: 2px;
      font-weight: 900;
      display: inline-block;
      width: 150px;
      vertical-align: top;
      margin: 0;
      padding: 0;
      text-transform: uppercase;
    }
  }
}
