.Header {
  $block: &;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 25px;
  background-color: white;

  &__content-container {
    display: flex;
    &__logo {
      width: 55px;
      margin-right: 5px;
    }

    &__menu {
      display: flex;
      flex-direction: column;
      a {
        &:not(:first-of-type) {
          @media screen and (max-width: 500px) {
            margin-left: 10px;
          }
          @media screen and (max-height: 500px) {
            margin-left: 25px;
          }
        }
        @media screen and (max-width: 374px) {
          letter-spacing: unset;
          font-size: 10px;
        }
      }
      @media screen and (max-width: 500px) {
        flex-direction: row;
        align-items: center;
        flex-grow: 1;
        height: 55px;
      }
      @media screen and (max-height: 500px) {
        flex-direction: row;
        align-items: center;
        flex-grow: 1;
        height: 55px;
      }
    }
  }
  @media screen and (max-width: 300px) {
    padding: unset;
  }
}
