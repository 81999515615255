.ImageTile {
  $block: &;
  width: 50%;
  padding-top: 12vh;
  padding-bottom: 12vh;

  &__full-width {
    width: 100%;
    height: auto;
    padding: unset;
    max-width: 1500px;
  }
  &__left {
    margin-right: auto;
  }
  &__right {
    margin-left: auto;
  }

  &:first-of-type {
    margin-top: 50px;
  }
}
