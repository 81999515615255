.Styling {
  $block: &;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  &__artist-link {
    h1 {
      font: inherit;
      vertical-align: baseline;
      width: 100%;
      text-align: left;
      font-size: 50px;
      line-height: 50px;
      font-family: helvetica;
      text-transform: uppercase;
      letter-spacing: 2px;
      margin: 0;
      @media screen and (max-width: 500px) {
        font-size: 2em;
      }
    }
  }
  @media screen and (min-width: 501px) {
    margin-top: 150px !important;
  }
}
