.App {
  $block: &;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  > *:not(.App__header):not(.Header) {
    &:not(.Styling) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    height: 100%;
    width: 100%;
    margin-top: 120px;
    @media screen and (max-width: 500px) {
      margin-top: 105px;
    }
    @media screen and (max-height: 500px) {
      margin-top: 105px;
    }
  }

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 35%;

    &__logo {
      width: 500px;

      img {
        width: 100%;
      }

      @media screen and (max-width: 500px) {
        margin: unset;
        width: unset;
      }
    }
    @media screen and (max-width: 500px) {
      margin: 0px 10%;
    }
  }
}
